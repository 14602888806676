import React from 'react';
import { Fragment } from 'preact';
export class TextInput extends React.Component {

    onValueChanged = (event) => {
        this.props.field.setValue(event.target.value, this.props.userEditKey);
        this.props.onValueChanged(this.props.field);
    }

    render() {

        return (
            <Fragment>
                {
                    (this.props.editMode == true) &&
                    <input type="text"
                        id={this.props.field.inputElementId}
                        name={this.props.field.inputElementName}
                        value={this.props.field.value}
                        class={this.props.cssClass}
                        onChangeCapture={this.onValueChanged}/>
                }
                {
                    (this.props.editMode == false) &&
                    this.props.field.getCurrentValue()
                }
            </Fragment>
        );
    }

}
